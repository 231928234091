#job-request-form {
  display: flex;
  flex-direction: column; /* Ensure items are stacked vertically */
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background-color: #FBF9EF; /* Background to match the overall theme */
}

.calendly-embed-container {
  margin-top: 20px;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}


#job-request-form h2 {
  margin-bottom: 20px; /* Space between the heading and form */
}

#job-request-form iframe {
  max-width: 800px;
  width: 100%; /* Ensure the form takes up full width */
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for aesthetic */
}


  /* Form Section for Mobile */
@media (max-width: 200px) {
  /* Job request form adjustments */
  #job-request-form {
    padding: 10px;              /* Reduce padding */
    width: 100%;                /* Full width */
  }

  iframe {
    width: 100%;                /* Full width for Google Form */
    height: 600px;              /* Adjust form height for smaller screens */
  }
  }
  