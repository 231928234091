body, html {
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fbf7e3;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.center-content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 0.9em;
}
.van-section, .plow-section {
  padding: 2rem 1rem;
  text-align: center;
  /* Remove margin if you want sections to be directly adjacent */
  margin: 0;
  /* Or keep only bottom margin if needed */
  /* margin: 0 0 2rem 0; */
}

.center-content h1 {
  margin: 0;
  margin-bottom: 10px;
}

.phone-number {
  margin: 0;
  font-size: 1.2em;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links li a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  font-weight: bold;
}

.nav-buttons {
  display: flex;
  gap: 20px;
}

.plow-section img {
  max-width: 100%;
  height: auto;
  width: auto;
}

@media (max-width: 768px) {
  .plow-section img {
    width: 90%; /* Slightly less than full width for margins */
    margin: 0 auto;
    display: block;
  }
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    gap: 20px;
  }

  .center-content {
    position: static;
    transform: none;
    margin: 20px 0;
  }

  .nav-links {
    display: none;
  }

  .nav-buttons {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .nav-buttons button {
    width: 90%;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
  }
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      gap: 20px;
    }
  
    .nav-links {
      /* Remove display: none and add proper mobile styling */
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 15px;
      margin-bottom: 10px;
      flex-wrap: wrap; /* Allows items to wrap on very small screens */
    }
  
    .nav-links li {
      margin-right: 0; /* Remove right margin since we're using gap */
    }
  }
}