// Variables
$background-color: #fbf9ef;
$button-color: #6b8e23;
$button-hover-color: #556b2f;
$text-color: white;
$max-image-width: 60vw;
$max-image-height: 200px;
$mobile-width: 768px;

// Hero Section
// .hero {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   width: 100%; /* Ensure full width */
//   padding: 20px 0;
//   min-height: 60vh; /* Adjust vertical height */
//   background-color: $background-color;
// }

// Image Container
// .hero-image-container {
//   margin-top: -10%;
//   margin-bottom: -40%;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   max-width: 1000px;
//   padding-top: 2vh; /* Adjusted padding */
//   padding-bottom: 9vh; /* Adjusted padding */
  
//   img {
//     max-width: 600px; /* Adjust relative to the viewport */
//     height: auto; /* Keep the aspect ratio */
//     margin-top: 10px; /* Space below the phone number */
//     max-height: 750px; /* Limit maximum height */
//   }
// }

// Truck Image
// .hero-image {
//   max-width: $max-image-width;
//   height: auto; /* Keep the aspect ratio */
//   margin-top: 20px; /* Keep it consistent below the phone number */
//   max-height: $max-image-height;
// }

// Buttons
.hero-buttons {
  display: center;
  justify-content: center; /* Center the buttons horizontally */
  align-items: center; /* Center vertically if needed */
  width: 100%; /* Ensure the container spans the full width */
  gap: 20px; /* Add space between buttons */
  margin-top: 30px;

  button {
    padding: 12px 25px;
    background-color: $button-color;
    color: $text-color;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    
    &:hover {
      background-color: $button-hover-color;
    }
  }
}

// MUI Button
.button-primary {
  background-color: $button-color;
  color: $text-color;
  border: none;
}

.button-secondary {
  background-color: transparent;
  color: $button-color;
  border: 2px solid $button-color;
}

// Mobile View
@media (max-width: $mobile-width) {
  .hero {
    text-align: center;
    font-size: 17px;
  }

  h1 {
    font-size: 17px; /* Smaller font for mobile */
  }

  p {
    font-size: 11px;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 10px; /* Reduce gap between buttons */
    width: 100%; /* Full width */
    
    button {
      width: 100%; /* Full-width buttons on mobile */
      padding: 12px 15px;
    }
  }

  .hero-image {
    max-width: auto;
    height: auto;
    margin-top: -50%; /* Adjust this value to reduce the space above the image */
    margin-bottom: -20px; /* Adjust this value to reduce space below the image */
    padding: 0; /* Remove any extra padding */
  }
}
