/* Services Section */
.services {
    padding: 50px 20px; /* Add more padding around the section */
    background-color: #FBF9EF; /* Background to match the overall theme */
    margin: 0;
    margin-top: -1px;
  }
  .van-section {
    padding: 2rem 1rem;
    text-align: center;
    margin: 0;
    margin-top: -1px; /* Add this line to remove the gap */
    background-color: #f9f7eb;
}
  .van-carousel {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    margin-top: -1px; /* Add this line to remove the gap */

    position: relative;
  }
  
  .carousel-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
  }
  
  .image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .carousel-button:hover {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .carousel-button.left {
    left: 0;
  }
  
  .carousel-button.right {
    right: 0;
  }
  
  .carousel-dots {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 16px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: none;
    background-color: #ccc;
    cursor: pointer;
    padding: 0;
    transition: all 0.3s ease;
  }
  
  .dot.active {
    background-color: #333;
    transform: scale(1.2);
  }
  
  @media (max-width: 768px) {
    .carousel-container {
      padding: 0 20px;
    }
  
    .carousel-button {
      width: 32px;
      height: 32px;
    }
  }
  .services h2 {
    text-align: center;
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .services ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
    font-size: 18px;
  }
  
  .services li {
    margin-bottom: 10px; /* Add space between service items */
  }
  